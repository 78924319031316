import moment       from "moment";
import { cloneDeep }  from "lodash";
import accumulator  from "@/mixins/statistics/InviteLinks/accumulator/accumulator";
import activePeriod from "@/mixins/statistics/InviteLinks/active-period/active-period";
import fixedValues  from "@/mixins/statistics/InviteLinks/fixed-values/fixed-values";

export default {
  mixins : [ accumulator, activePeriod, fixedValues ],
  methods: {
    prepareStatData(ctx, data, isDayApiCall, fromDate, toDate) {
      const resObject = {};
      const timeTicks = [];
      const dataTicks = [];
      let from        = "";
      let to          = "";
      if(isDayApiCall) {
	from = moment().add(-7, "days").startOf("day");
	to   = moment().toISOString();
      } else {
	from = moment(fromDate);
	to   = moment(toDate);
      }

      while(!from.isAfter(to)) {
	timeTicks.push(from.format("YYYY-MM-DD").toLocaleLowerCase());
	dataTicks.push(0);
	from.add(1, "day");
      }

      data.map(item => {
	const enter_unique_first_users         = {
	  name: isDayApiCall ? ctx.$t("enter_unique_first_users") : "enter_unique_first_users",
	  data: cloneDeep(dataTicks),
	};
	const enter_unique_repeated_users      = {
	  name: "enter_unique_repeated_users",
	  data: cloneDeep(dataTicks),
	};
	const leave_unique_users               = {
	  name: isDayApiCall ? ctx.$t("leave_unique_users") : "leave_unique_users",
	  data: cloneDeep(dataTicks),
	};
	const kick_unique_users                = {
	  name: isDayApiCall ? ctx.$t("kick_unique_users") : "kick_unique_users",
	  data: cloneDeep(dataTicks),
	};
	const active_period_second_enter_users = {
	  name: "active_period_second_enter_users",
	  data: cloneDeep(dataTicks),
	};
	const active_period_leave_users        = {
	  name: "active_period_leave_users",
	  data: cloneDeep(dataTicks),
	};
	const active_period_kick_users         = {
	  name: "active_period_kick_users",
	  data: cloneDeep(dataTicks),
	};

	item.stat.map(item => {
	  let newItemTime = item.time.split(" ")[ 0 ];
	  if(timeTicks.includes(newItemTime)) {
	    const index = timeTicks.indexOf(newItemTime);
	    if(item.enter_unique_first_users) {
	      enter_unique_first_users.data[ index ] += item.enter_unique_first_users;
	    }
	    if(item.enter_unique_repeated_users) {
	      enter_unique_repeated_users.data[ index ] += item.enter_unique_repeated_users;
	    }
	    if(item.leave_unique_users) {
	      leave_unique_users.data[ index ] += item.leave_unique_users;
	    }
	    if(item.kick_unique_users) {
	      kick_unique_users.data[ index ] += item.kick_unique_users;
	    }

	    if(item.active_period_second_enter_users) {
	      active_period_second_enter_users.data[ index ] = item.active_period_second_enter_users;
	    }
	    if(item.active_period_leave_users) {
	      active_period_leave_users.data[ index ] = item.active_period_leave_users;
	    }
	    if(item.active_period_kick_users) {
	      active_period_kick_users.data[ index ] = item.active_period_kick_users;
	    }
	  }
	});

	resObject[ item.link.code ? item.link.code : "link_statistics_no_link" ] = {
	  title : item.link.link,
	  name  : item.link.title ? item.link.title : ctx.$t("link_statistics_no_link"),
	  series: [ enter_unique_first_users, enter_unique_repeated_users, leave_unique_users, kick_unique_users, active_period_second_enter_users, active_period_leave_users, active_period_kick_users ],
	};
      });
      return resObject;
    },

    getTimeTicks(fromDate, toDate) {
      const from      = moment(fromDate);
      const to        = moment(toDate);
      const timeTicks = [];
      const dataTicks = [];
      while(!from.isAfter(to)) {
	timeTicks.push(from.format("YYYY-MM-DD HH:mm:ss").toLocaleLowerCase());
	dataTicks.push(0);
	from.add(1, "day");
      }
      return timeTicks;
    },

    setObjectForChart(ctx) {
      return new Promise(resolve => {
	const actualLinks = ctx.customIntervalLinks;
	const length      = ctx.selected_links.length;
	const tags        = ctx.selected_links;
	const from        = ctx.date.from;
	const to          = ctx.date.to;

	const values = Object.values(actualLinks);
	let resObj   = {
	  enter_unique_first_users        : {
	    data   : [],
	    options: {},
	  },
	  leave_unique_users              : {
	    data   : [],
	    options: {},
	  },
	  kick_unique_users               : {
	    data   : [],
	    options: {},
	  },
	  enter_unique_repeated_users     : {
	    data   : [],
	    options: {},
	  },
	  active_period_second_enter_users: {
	    data   : [],
	    options: {},
	  },
	  active_period_leave_users       : {
	    data   : [],
	    options: {},
	  },
	  active_period_kick_users        : {
	    data   : [],
	    options: {},
	  },
	  accumulator                     : [],
	};

	const itemsToChart = values.filter(v => v.name ? tags.includes(v.name) || !v.name : tags.includes(v.title));

	itemsToChart.forEach((item) => {
	  item.series.forEach((metric) => {
		 if(metric.name === "enter_unique_first_users") {
		   resObj.enter_unique_first_users.data.push({
		     name: item.name ? item.name : item.title,
		     data: metric.data,
		   });
		   resObj.enter_unique_first_users.options = fixedValues.methods.setOptionsAndSeries(ctx, metric.data,
			metric.name, from, to);
		 }
		 if(metric.name === "enter_unique_repeated_users") {
		   resObj.enter_unique_repeated_users.data.push({
		     name: item.name ? item.name : item.title,
		     data: metric.data,
		   });
		   resObj.enter_unique_repeated_users.options = fixedValues.methods.setOptionsAndSeries(ctx, metric.data,
			metric.name, from, to);
		 }
		 if(metric.name === "leave_unique_users") {
		   resObj.leave_unique_users.data.push({
		     name: item.name ? item.name : item.title,
		     data: metric.data,
		   });
		   resObj.leave_unique_users.options = fixedValues.methods.setOptionsAndSeries(ctx, metric.data, metric.name, from, to);
		 }
		 if(metric.name === "kick_unique_users") {
		   resObj.kick_unique_users.data.push({
		     name: item.name ? item.name : item.title,
		     data:
		     metric.data,
		   });
		   resObj.kick_unique_users.options = fixedValues.methods.setOptionsAndSeries(ctx, metric.data, metric.name, from, to);
		 }
		 if(metric.name === "active_period_second_enter_users") {
		   resObj.active_period_second_enter_users.data.push({
		     name: item.name ? item.name : item.title,
		     data: metric.data,
		   });
		   resObj.active_period_second_enter_users.options = activePeriod.methods.setOptionsAndSeries(ctx, resObj.active_period_second_enter_users.data, length, metric.name, from, to);
		 }
		 if(metric.name === "active_period_leave_users") {
		   resObj.active_period_leave_users.data.push({
		     name: item.name ? item.name : item.title,
		     data: metric.data,
		   });
		   resObj.active_period_leave_users.options = activePeriod.methods.setOptionsAndSeries(ctx, resObj.active_period_leave_users.data, length, metric.name, from, to);
		 }
		 if(metric.name === "active_period_kick_users") {
		   resObj.active_period_kick_users.data.push({
		     name: item.name ? item.name : item.title,
		     data: metric.data,
		   });
		   resObj.active_period_kick_users.options = activePeriod.methods.setOptionsAndSeries(ctx, resObj.active_period_kick_users.data, length, metric.name, from, to);
		 }
	       },
	  );
	});

	resObj.accumulator.push(accumulator.methods.accumulateParams(ctx, resObj, from, to));
	resolve(resObj);
      });

    },
  },
};

