import StackedBarChart       from "@/includes/logic/StackedBarChart";
import inviteLinksStatistics from "@/mixins/statistics/InviteLinks/inviteLinksStatistics";

export default {
  data() {
    return {};
  },
  methods: {
    accumulateParams(ctx, obj, from, to) {
      const resultObj                = {
	options: {},
	series : [],
      };
      const seriesResult             = [];
      const enter_unique_first_users = [];
      const leave_unique_users       = [];
      const kick_unique_users        = [];

      if(obj.enter_unique_first_users) {
	enter_unique_first_users.push(obj.enter_unique_first_users.data.map(d => d.data));
	seriesResult.push(this.sumArray(enter_unique_first_users[ 0 ], ctx.$t("enter_unique_first_users")));
      }
      if(obj.leave_unique_users) {
	leave_unique_users.push(obj.leave_unique_users.data.map(d => d.data));
	seriesResult.push(this.sumArray(leave_unique_users[ 0 ], ctx.$t("leave_unique_users")));
      }
      if(obj.kick_unique_users) {
	kick_unique_users.push(obj.kick_unique_users.data.map(d => d.data));
	seriesResult.push(this.sumArray(kick_unique_users[ 0 ], ctx.$t("kick_unique_users")));
      }

      const stackedBarChart       = new StackedBarChart(ctx.$t('link_statistics_stacked_chart'));
      stackedBarChart.xAxisLabels = inviteLinksStatistics.methods.getTimeTicks(from, to);
      resultObj.series            = seriesResult;
      resultObj.options           = stackedBarChart.options;

      return resultObj;
    },

    sumArray(array, name) {
      const newArray = [];
      array.forEach(sub => {
	sub.forEach((num, index) => {
	  if(newArray[ index ]) {
	    newArray[ index ] += num;
	  } else {
	    newArray[ index ] = num;
	  }
	});
      });
      return {
	name: name,
	data: newArray,
      };
    },

  },
};
