









































































import Api from "@/includes/logic/Api";
import inviteLinksStatistics from "@/mixins/statistics/InviteLinks/inviteLinksStatistics";
import { InviteLinkChart } from "@/views/chat/invite-links/InviteLinkChart.model";
import { InputSetups } from '@/mixins/input-setups';
import PageTitle from '@/components/PageTitle.vue';
import MobileLandscapeTriggerLayout from "@/components/MobileLandscapeTriggerLayout.vue";
import InviteLinksStatChart from "@/components/chat/InviteLinks/InviteLinksStatChart.vue";
import { errorNotification } from '@/includes/NotificationService'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index';
import { FieldData } from 'piramis-base-components/src/components/Pi/types';

import Component from "vue-class-component";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { Mixins, Watch } from "vue-property-decorator";

@Component({
  'components': {
    PageTitle,
    InviteLinksStatChart,
    VueApexCharts,
    MobileLandscapeTriggerLayout,
    ConfigField,
    EmptyData
  },
})
export default class LinkStatistics extends Mixins(UseFields, InputSetups) {
  data() {
    return {
      options: [],
    }
  }

  isDataSet = false

  linksToShow: any = null;

  //date/time
  date = {
    from: moment().add(-30, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  }

  //chips for selected links
  selected_links: string[] = []

  @Watch('selected_links')
  onTagsChange(): void {
    this.$baseTemplate.loader.open();
    setTimeout(() => {
      this.getStatisticsBySelectedLinks();
      this.$baseTemplate.loader.close();
    }, 5)
  }

  //links from api with custom interval
  customIntervalLinks: any;

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any): void => {
      args.model[args.key] = value
    }
    return args
  }

  getOptions(): Promise<any> {
    return new Promise(resolve => resolve(this.$data.options))
  }

  //get links stat from api
  getInviteLinksStat(isFirstCall?: boolean) {
    this.isDataSet = false
    let selectedLink = ''
    if (isFirstCall) selectedLink = this.$route.params.linkCode;
    this.$baseTemplate.loader.open();
    return new Promise((resolve, reject) => {
      Api.getinvitelinksstat('tg', {
        chat_id: this.$store.state.chatState.chat.chat_id,
        interval: 'hour',
        from: this.date.from,
        to: this.date.to
      })
        .then(({ data }) => {
          this.customIntervalLinks = inviteLinksStatistics.methods.prepareStatData(this, data.links, false, this.date.from, this.date.to);
          if (selectedLink && this.customIntervalLinks[selectedLink]) {
            this.selected_links.push(this.customIntervalLinks[selectedLink].name)
            this.linksToShow = this.customIntervalLinks[selectedLink];
          }
          if (this.selected_links.length) {
            this.getStatisticsBySelectedLinks()
          }
          resolve(this.customIntervalLinks)
        })
        .catch(err => {
          errorNotification(err);
          reject(err)
        })
        .finally(() => this.$baseTemplate.loader.close())
    })
  }

  //push links to select when component is mounted
  pushLinksToSelect(links: InviteLinkChart[]) {
    Object.values(links).forEach((link: InviteLinkChart) => {
      this.$data.options.push({
        label: link.name,
        value: link.name,
      })
    })
  }

  //get statistics for link
  getStatisticsBySelectedLinks() {
    if (!this.selected_links.length) {
      this.linksToShow = null;
      return;
    }
    inviteLinksStatistics.methods.setObjectForChart(this)
      .then(res => this.linksToShow = res)
      .finally(() => this.isDataSet = true)
  }

  get diffLessThenHalfYear(): boolean {
    return (moment(this.date.from).diff(moment(this.date.to), 'd') < -180)
  }

  get isRightDate() {
    return (
      (moment(this.date.from).isBefore(this.date.to, "day"))
      && (!moment(this.date.to).isAfter(moment(), "day"))
    )
      && !this.diffLessThenHalfYear

  }

  //lifecycle
  async mounted(): Promise<void> {
    this.$baseTemplate.saveButton.hide();
    await this.$store.dispatch("getStatisticsLocales")

    this.getInviteLinksStat(true)
      .then((data: any) => {
        this.pushLinksToSelect(data)
      })
  }

  destroyed() {
    this.$baseTemplate.saveButton.show();
  }
}
